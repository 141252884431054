import { useRef, useEffect } from 'react';

export const cst = Object.freeze({
  DRAG_VALUE: 2,
  LIMIT_X_MARGIN: 50,
  LIMIT_Y_MARGIN: 120,
});

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default {};
