import RoomManager from 'src/components/Room';
import SplashScreen from 'src/components/SplashScreen';
import React from 'react';

const routes = [
  {
    path: '/',
    exact: true,
    component: <SplashScreen />,
    public: true,
  },
  {
    path: '/map',
    exact: true,
    component: 'MapManager',
    public: true,
  },
  {
    path: '/room1',
    exact: true,
    component: <RoomManager
      name="room1"
    />,
    public: true,
  },
  {
    path: '/room2',
    exact: true,
    component: <RoomManager
      name="room2"
    />,
    public: true,
  },
  {
    path: '/room3',
    exact: true,
    component: <RoomManager
      name="room3"
    />,
    public: true,
  },
  {
    path: '/room4',
    exact: true,
    component: <RoomManager
      name="room4"
    />,
    public: true,
  },
  {
    path: '/room5',
    exact: true,
    component: <RoomManager
      name="room5"
    />,
    public: true,
  },
  {
    path: '/room6',
    exact: true,
    component: <RoomManager
      name="room6"
    />,
    public: true,
  },
];

export default routes;
