import React, { useEffect } from 'react';
import Lottie from 'react-lottie-player';
import css from './styles/frame-container.module.scss';

interface LottieAnimationProps {
  json: string;
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({ json }) => (
  <div className={css.lottie}>
    <Lottie
      loop
      // eslint-disable-next-line import/no-dynamic-require
      animationData={require(`../assets/${json}`)}
      play
      style={{ width: '100%', height: '100%' }}
    />
  </div>
);
export default LottieAnimation;
