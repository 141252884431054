import React, { useEffect, useState } from 'react';
import mapStyle from './styles/tuto-map.module.scss';
import style from './styles/tuto-panel.module.scss';
import image from '../assets/icone-tutoriel.svg';

interface TutoPanelProps {
  // eslint-disable-next-line no-unused-vars
  onVisibility: (value :boolean) => void;
}

export const TutoPanel:
React.FC<TutoPanelProps> = ({ onVisibility }) => {
  const [visible, setVisible] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);
  useEffect(() => {
    const id = setTimeout(() => {
      setDisabled(false);
      clearTimeout(id);
    }, 2400);
    return () => {
      setVisible(true);
      setDisabled(true);
      clearTimeout(id);
    };
  }, []);
  return (
    <>
      {
        visible
        && (
          <>
            <div
              className={`${mapStyle.tutoMap}`}
              style={{
                zIndex: 999,
              }}
            >
              <div className="force-first">
                <span>Close the 360° view</span>
              </div>
            </div>
            <div className={style.tutoPanel}>
              <div>
                <div className="image">
                  <span
                    style={{
                      backgroundImage: `url(${image})`,
                    }}
                  />
                </div>
                <p>
                  <span>
                    Click on the info buttons to open the pop-up windows.
                    Close the 360° room view by clicking again on the 360°
                    button.
                  </span>
                </p>
              </div>
              <button
                type="button"
                disabled={disabled}
                style={{
                  opacity: `${disabled ? 0.5 : 1}`,
                }}
                onClick={() => {
                  setVisible(false);
                  onVisibility(false);
                }}
              >OK
              </button>
            </div>
          </>
        )
      }
    </>
  );
};

export default TutoPanel;
