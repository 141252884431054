import React, {
  useEffect, useRef, useCallback, useState,
} from 'react';
import Marzipano, { Viewer } from 'marzipano';
import { RoomData } from 'global';
import { ROOMS } from 'src/Constants';
import { useNavigate } from 'react-router-dom';
import plusIcon from '../assets/zoom_in.svg';
import minusIcon from '../assets/zoom_out.svg';
import button360 from '../assets/bouton_360.svg';
import mapCss from './styles/map-manager.module.scss';
import css from './styles/room.module.scss';
import MultipleModal from './MultipleModal';
import { TutoPanel } from './TutoPanel';

const CAM_FRICTION :number = 3;
const CAM_VELOCITY :number = 0.7;
interface RoomProps {
  name: string;
}
interface TitlePanelProps {
  title :string;
}
const TitlePanel: React.FC<TitlePanelProps> = ({ title }) => (
  <>
    <div className={css.titlePanel}>
      <div>
        <span>{title}</span>
      </div>
    </div>
  </>
);

const InfoHotSpots: React.FC<any> = ({
  lastIndex, name, scene, onDisplayModal,
}) => {
  const ihs :any = [];
  const data :RoomData = ROOMS[name];
  const t = data.infoHotspots.map((value :any, index :number) => {
    const displayModal = useCallback((modalData :any) => {
      onDisplayModal(modalData);
    }, []);
    const ref: any = useRef<HTMLDivElement>(null);
    ihs.push(ref);
    useEffect(() => {
      if (lastIndex !== -1) {
        ihs[lastIndex].current.classList.remove('active');
        ihs.forEach((ih :any) => {
          if (ih !== ref) {
            ih.current.classList.remove('force-disabled');
          }
        });
      }
    }, [lastIndex]);
    return (
      <div
        ref={ref}
        role="button"
        tabIndex={0}
        key={`hot-spot-info-${index}`}
        className={`info-hot-spot ${value.info ? 'no-click' : ''}`}
        onMouseEnter={() => {
          ref.current.classList.add('force-top-layer');
        }}
        onMouseLeave={() => {
          ref.current.classList.remove('force-top-layer');
        }}
        onClick={() => {
          if (!value.info) {
            displayModal({ index, pages: value.modal });
            ref.current.classList.add('active');
            ihs.forEach((ih :any) => {
              if (ih !== ref) {
                ih.current.classList.add('force-disabled');
              }
            });
          }
        }}
      >
        <i>i</i>
        <div>
          <span>{value.title}</span>
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (scene) {
      ihs.forEach((ref: any, index :number) => {
        scene.hotspotContainer()
          .createHotspot(ref.current, {
            yaw: data.infoHotspots[index].yaw,
            pitch: data.infoHotspots[index].pitch,
          });
      });
    }
  }, [scene]);

  return (
    <>
      {t}
    </>
  );
};

export const Room: React.FC<RoomProps> = ({ name }) => {
  const zoomIn = useRef<HTMLButtonElement>(null);
  const zoomOut = useRef<HTMLButtonElement>(null);
  const room = useRef<HTMLDivElement>(null);

  const [scene, setScene] = useState<any>(null);
  const [modalData, setModalData] = useState<any>(null);
  const [lastHotSpotIndex, setLastHotSpotIndex] = useState<number>(-1);
  const [tutoIsVisible, setTutoIsVisible] = useState<boolean>(true);

  useEffect(() => () => {
    setScene(null);
    setModalData(null);
    setLastHotSpotIndex(-1);
    setTutoIsVisible(true);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (room?.current && zoomIn?.current) {
      const viewer = new Viewer(room.current, {
        controls: {
          mouseViewMode: 'drag',
        },
      });

      const controls = viewer.controls();
      controls.registerMethod('inElement',
        new Marzipano.ElementPressControlMethod(
          zoomIn.current, 'zoom', -CAM_VELOCITY, CAM_FRICTION,
        ), true);

      controls.registerMethod('outElement',
        new Marzipano.ElementPressControlMethod(
          zoomOut.current, 'zoom', CAM_VELOCITY, CAM_FRICTION,
        ), true);

      const limiter = Marzipano.RectilinearView.limit.traditional(
        Math.floor(1024 * 1.5),
        (100 * Math.PI) / 180,
        (120 * Math.PI) / 180,
      );
      const { initialViewParameters } = ROOMS[name];
      const view = new Marzipano.RectilinearView({
        pitch: initialViewParameters.pitch,
        yaw: initialViewParameters.yaw,
        fov: Math.PI / 2,
      }, limiter);
      const geometry = new Marzipano.CubeGeometry(ROOMS[name].levels);
      const data:RoomData = ROOMS[name];
      const source = Marzipano.ImageUrlSource.fromString(
        `/tiles/${data.id}/{z}/{f}/{y}/{x}.jpg`, {
          cubeMapPreviewUrl: `/tiles/${data.id}/preview.jpg`,
        },
      );
      const scn = viewer.createScene({
        source,
        geometry,
        view,
        pinFirstLevel: true,
      });
      scn.switchTo();
      setScene(scn);
    }
  }, [room?.current, zoomIn?.current]);

  const Switch360Button = useCallback(() => (
    <button
      type="button"
      style={{
        background: '#CC0033',
        right: '20px',
        bottom: '160px',
      }}
      className={`${mapCss.switchButton360}`}
      onClick={() => {
        navigate(-1);
      }}
    >
      <span
        className="inner"
        style={{
          filter: 'grayscale(100%) brightness(2)',
          backgroundImage: `url(${button360})`,
        }}
      />
    </button>
  ), []);

  const ZoomButtons = useCallback(() => (
    <div
      tabIndex={0}
      role="button"
      className="zoom-button"
      onClick={(e :any) => {
        e.stopPropagation();
      }}
    >
      <button
        type="button"
        ref={zoomIn}
        style={{
          backgroundImage: `url(${plusIcon})`,
        }}
      />
      <button
        type="button"
        ref={zoomOut}
        style={{
          backgroundImage: `url(${minusIcon})`,
        }}
      />
    </div>
  ), []);

  const onDisplayModal = useCallback((data :any) => {
    setModalData(data);
  }, []);

  return (
    <>
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        {
          modalData && (
            <MultipleModal
              data={modalData}
              onClose={(index :number) => {
                setModalData(null);
                setLastHotSpotIndex(index);
              }}
            />
          )
        }
        <TutoPanel
          onVisibility={(value :boolean) => {
            setTutoIsVisible(value);
          }}
        />
        <TitlePanel title={ROOMS[name].title} />
        <div
          className={`notranslate ${css.room}`}
          translate="no"
        >
          <Switch360Button />
          <ZoomButtons />
          {scene ? (
            <InfoHotSpots
              name={name}
              scene={scene}
              lastIndex={lastHotSpotIndex}
              onDisplayModal={(data :any) => {
                onDisplayModal(data);
                setLastHotSpotIndex(-1);
              }}
            />
          ) : ''}
          <div
            ref={room}
            className="room-view"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Room;
