import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { isMobile } from 'react-device-detect';
import mapStyle from './styles/map-manager.module.scss';
import style from './styles/hovers.module.scss';

const FILL_COLOR = '#DAE6EF';
interface HoversProps {
  // eslint-disable-next-line no-unused-vars
  onHover: (id :string) => void;
  // eslint-disable-next-line no-unused-vars
  onSelect: (value :string, e :any) => void;
  unselectArea: any;
  zoom: number;
  status: string;
}
/* eslint-disable max-len */
const Hovers :React.FC<HoversProps> = ({
  onHover, onSelect, unselectArea, zoom, status,
}) => {
  const orangeRef = useRef<SVGGElement>(null);
  const violetRef = useRef<SVGGElement>(null);
  const redRef = useRef<SVGGElement>(null);
  const greenRef = useRef<SVGGElement>(null);
  const blueRef = useRef<SVGGElement>(null);
  const yellowRef = useRef<SVGGElement>(null);
  const greyRef = useRef<SVGGElement>(null);
  const [enterElement, setEnterElement] = useState<boolean>(false);
  const disableHover = () => {
    if (orangeRef?.current
      && violetRef?.current
      && redRef?.current
      && greenRef?.current
      && blueRef?.current
      && yellowRef?.current
      && greyRef?.current) {
      orangeRef.current.style.opacity = '0';
      violetRef.current.style.opacity = '0';
      redRef.current.style.opacity = '0';
      greenRef.current.style.opacity = '0';
      blueRef.current.style.opacity = '0';
      yellowRef.current.style.opacity = '0';
      greyRef.current.style.opacity = '0';
      onHover('');
    }
  };
  const enableHover = () => {
    if (orangeRef !== null && orangeRef.current
      && violetRef !== null && violetRef.current
      && redRef !== null && redRef.current
      && greenRef !== null && greenRef.current
      && blueRef !== null && blueRef.current
      && yellowRef !== null && yellowRef.current
      && greyRef !== null && greyRef.current) {
      orangeRef.current.style.opacity = '1';
      violetRef.current.style.opacity = '1';
      redRef.current.style.opacity = '1';
      greenRef.current.style.opacity = '1';
      blueRef.current.style.opacity = '1';
      yellowRef.current.style.opacity = '1';
      greyRef.current.style.opacity = '1';
      onHover('');
    }
  };
  const onMouseEnter = useCallback((element :any, id :string) => {
    if (zoom === 0) {
      enableHover();
      element.current.style.opacity = '0';
      setEnterElement(true);
      onHover(id);
    }
  }, [zoom]);
  const onMouseLeave = useCallback((element :any) => {
    if (!isMobile) {
      if (zoom === 0) {
        element.current.style.opacity = '1';
        setEnterElement(false);
        onHover('');
      }
    }
  }, [zoom]);
  const [currentElement, setCurrentElement] = useState<any>({});
  useEffect(() => {
    if (currentElement.id !== ''
      && currentElement.ref?.current) {
      if (status === 'on-tap') {
        onMouseEnter(currentElement.ref, currentElement.id);
      }
    }
  }, [status, currentElement]);

  useEffect(() => {
    let id :any;
    if (!enterElement) {
      id = setTimeout(() => {
        if (!enterElement) {
          disableHover();
        }
        clearTimeout(id);
      }, 500);
    }
    return () => {
      clearTimeout(id);
    };
  }, [enterElement]);

  useEffect(() => {
    disableHover();
  }, []);
  useEffect(() => {
    if (unselectArea?.value === 1) {
      disableHover();
      onSelect('', null);
      setCurrentElement({});
    }
  }, [unselectArea]);

  useEffect(() => {
    if (zoom === 0) {
      disableHover();
    }
  }, [zoom]);
  return (
    <>
      <svg
        className={style.hovers}
        style={{
          zIndex: 3,
        }}
        x="0px"
        y="0px"
        width="1031.828px"
        height="580.713px"
        viewBox="0 0 1031.828 580.713"
      >
        <g
          className={mapStyle.hoverLayer}
          id="ORANGE"
          ref={orangeRef}
          style={{
            pointerEvents: 'fill',
          }}
          onMouseEnter={() => {
            if (!isMobile) {
              onMouseEnter(orangeRef, 'orange');
            }
          }}
          onTouchStart={() => {
            setCurrentElement({ ref: orangeRef, id: 'orange' });
          }}
          onMouseLeave={() => {
            onMouseLeave(orangeRef);
          }}
          onClick={(e :any) => {
            e.stopPropagation();
            if (zoom === 0) {
              onMouseEnter(orangeRef, 'orange');
              onSelect('/room2', e);
              if (orangeRef.current !== null) {
                orangeRef.current.style.opacity = '0';
              }
            }
          }}
        >
          <path
            fill={FILL_COLOR}
            d="M430.542,468.156v-6.835l10.333-6.331c0,0,0-2.75,2.417-3.919c2.417-1.165,4.167-0.331,4.167-0.331
            l44.417-26.71v-10.456c0,0,0.333-1.542,1-2.208c0.383-0.385,3.583-2.335,10.667-6.042l6.375-3.625c0,0,1.083-0.375,1.958,0
            s1.667,1.122,1.75,1.958c0.083,0.834,0,7.125,0,7.125l14.25-8.5l5.708-11.916l17.667-10.042l61.167,34.958l31.875-29.875
            l22.209,13.167v63.372l-123.417,71.459L430.542,468.156z"
          />
        </g>
      </svg>
      <svg
        className={style.hovers}
        style={{
          zIndex: 4,
        }}
        x="0px"
        y="0px"
        width="1031.828px"
        height="580.713px"
        viewBox="0 0 1031.828 580.713"
      >
        <g
          className={mapStyle.hoverLayer}
          style={{
            pointerEvents: 'fill',
          }}
          ref={violetRef}
          id="VIOLET"
          onMouseEnter={() => {
            if (!isMobile) {
              onMouseEnter(violetRef, 'violet');
            }
          }}
          onTouchStart={() => {
            setCurrentElement({ ref: violetRef, id: 'violet' });
          }}
          onMouseLeave={() => {
            onMouseLeave(violetRef);
          }}
          onClick={(e :any) => {
            e.stopPropagation();
            if (zoom === 0) {
              onMouseEnter(violetRef, 'violet');
              onSelect('/room6', e);
              if (violetRef.current !== null) {
                violetRef.current.style.opacity = '0';
              }
            }
          }}
        >
          <path
            fill={FILL_COLOR}
            d="M386.083,333.741l-48.583,25.5v55.25l91.833,52.915v-6.583
            l47-28.5V384.99L386.083,333.741z M358.477,369.607v31.375l-16.188,9.375v-38.125l16.188-9.25V369.607z"
          />
        </g>
      </svg>
      <svg
        className={style.hovers}
        style={{
          zIndex: 5,
        }}
        x="0px"
        y="0px"
        width="1031.828px"
        height="580.713px"
        viewBox="0 0 1031.828 580.713"
      >
        <g
          className={mapStyle.hoverLayer}
          style={{
            pointerEvents: 'fill',
          }}
          ref={redRef}
          id="ROUGE_x2F_MAUVE"
          onMouseEnter={() => {
            if (!isMobile) {
              onMouseEnter(redRef, 'red');
            }
          }}
          onTouchStart={() => {
            setCurrentElement({ ref: redRef, id: 'red' });
          }}
          onMouseLeave={() => {
            onMouseLeave(redRef);
          }}
          onClick={(e :any) => {
            e.stopPropagation();
            if (zoom === 0) {
              onMouseEnter(redRef, 'red');
              onSelect('/room1', e);
              if (redRef.current !== null) {
                redRef.current.style.opacity = '0';
              }
            }
          }}
        >
          <path
            fill={FILL_COLOR}
            d="M398.313,290.177c0,0-0.188-0.75-0.563-0.75
            c-0.094,0-0.152,0-0.188,0c0,0,0.813-1.063,0.688-1.5c-0.125-0.438,0.5-1.375-0.125-1.438c-0.625-0.063-1.563,0-1.563,0
            s0.875-1.563-0.125-1.813s-1.375,0-1.375,0s-0.25-1.063-0.625-1.063s-0.938,0.563-1,1.125c-0.063,0.563-0.188,1.5-0.188,1.5
            s-0.25-1.375-0.625-2s-1.063-0.563-1.313-0.125c-0.25,0.438-0.313,1.5-0.313,1.5h-0.438l-51.5-29.313L329,203.74l-39.375-23.25
            v-20.813L165.5,232.156v83l142.688,82.271v-6.813l28.125-16V358.74l49.813-26.75l11.188,7.688l8.5-4.625l6.5-14.188l19.563-11.438
            L398.313,290.177z M314.102,257.044l-19.625-11.375l0.5-0.25l-0.125-39.875l19.25,11.188V257.044z"
          />
          <polygon
            fill={FILL_COLOR}
            points="342.289,371.169 358.477,361.732 358.477,360.607 342.289,370.169"
          />
        </g>
      </svg>
      <svg
        className={style.hovers}
        style={{
          zIndex: 6,
        }}
        x="0px"
        y="0px"
        width="1031.828px"
        height="580.713px"
        viewBox="0 0 1031.828 580.713"
      >
        <g
          className={mapStyle.hoverLayer}
          style={{
            pointerEvents: 'fill',
          }}
          ref={blueRef}
          id="BLEU"
          onMouseEnter={() => {
            if (!isMobile) {
              onMouseEnter(blueRef, 'blue');
            }
          }}
          onTouchStart={() => {
            setCurrentElement({ ref: blueRef, id: 'blue' });
          }}
          onMouseLeave={() => {
            onMouseLeave(blueRef);
          }}
          onClick={(e :any) => {
            e.stopPropagation();
            if (zoom === 0) {
              onMouseEnter(blueRef, 'blue');
              onSelect('/room5', e);
              if (blueRef.current !== null) {
                blueRef.current.style.opacity = '0';
              }
            }
          }}
        >
          <path
            fill={FILL_COLOR}
            d="M537.813,235.615l3.813-1.875l0.938,0.438l12.188-7.25
            c0,0,0.063-0.563,0.563-0.75s0.938,0,0.938,0s-0.063-1.313,0.5-1.625s1.75,0.625,1.75,0.625l2.813-2v-1.625l15.063-7.125
            l10.938-6.063l5.369-11.625l18.813-10.125h0.5V117.24L487.063,44.678l-115.625,67.438v69.063l0.688-0.438l70.5,40.25l31.938-29.563
            l61.813,36.813L537.813,235.615z"
          />
          <polygon
            fill={FILL_COLOR}
            points="491.72,224.69 491.498,264.982 471.581,253.524 471.998,253.274 471.998,212.982"
          />
          <polygon
            fill={FILL_COLOR}
            points="517.247,239.523 517.247,248.565 501.206,257.857 501.206,230.232"
          />
        </g>
      </svg>
      <svg
        className={style.hovers}
        style={{
          zIndex: 7,
        }}
        x="0px"
        y="0px"
        width="1031.828px"
        height="580.713px"
        viewBox="0 0 1031.828 580.713"
      >
        <g
          className={mapStyle.hoverLayer}
          style={{
            pointerEvents: 'fill',
          }}
          id="VERT"
          ref={greenRef}
          onMouseEnter={() => {
            if (!isMobile) {
              onMouseEnter(greenRef, 'green');
            }
          }}
          onTouchStart={() => {
            setCurrentElement({ ref: greenRef, id: 'green' });
          }}
          onMouseLeave={() => {
            onMouseLeave(greenRef);
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (zoom === 0) {
              onMouseEnter(greenRef, 'green');
              onSelect('/room4', e);
              if (greenRef.current !== null) {
                greenRef.current.style.opacity = '0';
              }
            }
          }}
        >
          <polygon
            fill={FILL_COLOR}
            points="667,309.615 670.439,307.615 671.625,308.24
            671.625,260.678 739.625,221.615 740.625,222.115 740.625,192.115 863.375,263.428 863.375,348.115 749.564,413.99
            749.564,398.178 675.875,355.865"
          />
          <path
            fill={FILL_COLOR}
            d="M630.581,345.523v-12.666l20.5-11.75c0,0,0,36.75,0,36.5 S630.581,345.523,630.581,345.523z"
          />
        </g>
      </svg>
      <svg
        className={style.hovers}
        style={{
          zIndex: 8,
        }}
        x="0px"
        y="0px"
        width="1031.828px"
        height="580.713px"
        viewBox="0 0 1031.828 580.713"
      >
        <g
          className={mapStyle.hoverLayer}
          style={{
            pointerEvents: 'fill',
          }}
          ref={yellowRef}
          id="JAUNE"
          onMouseEnter={() => {
            if (!isMobile) {
              onMouseEnter(yellowRef, 'yellow');
            }
          }}
          onTouchStart={() => {
            setCurrentElement({ ref: yellowRef, id: 'yellow' });
          }}
          onMouseLeave={() => {
            onMouseLeave(yellowRef);
          }}
          onClick={(e :any) => {
            e.stopPropagation();
            if (zoom === 0) {
              onMouseEnter(yellowRef, 'yellow');
              onSelect('/room3', e);
              if (yellowRef.current !== null) {
                yellowRef.current.style.opacity = '0';
              }
            }
          }}
        >
          <path
            fill={FILL_COLOR}
            d="M591.914,323.858c0,0,13.501,9.667,14.834,21.667s-2.501,30.999-13.334,39.666
            s-18,7.167-18,7.167l-23.976-12.619l-18.375,10.5l-1.604,3.225c0,0-16.379,3.561-59.379-24.272s-30.667-37-24.5-42.5
            s7.167-5.584,7.167-5.584l-0.333-34.325l75.5-44.59l24.334,14.167l0.166,45.333l22.334,13.167l2.5-5.833l0.604-0.043l10.25,5.813
            l0.25,0.75l-1.875,4.75l1.438,0.813l0.25,1.688L591.914,323.858z"
          />
        </g>
      </svg>
      <svg
        className={style.hovers}
        style={{
          zIndex: 9,
        }}
        x="0px"
        y="0px"
        width="1031.828px"
        height="580.713px"
        viewBox="0 0 1031.828 580.713"
      >
        <g
          onMouseEnter={() => {
            onHover('');
          }}
          onTouchStart={() => {
            onHover('');
          }}
          className={mapStyle.hoverLayer}
          ref={greyRef}
          style={{
            pointerEvents: 'none',
          }}
        >
          <path
            fill={FILL_COLOR}
            d="M675.083,356.574l-9.166-46.752l4.582-2.584l0.834,0.375
            v-47.333l68.332-39l0.668,0.417l0.125-29.792l-128.25-74.583v69.5L612,186.781l-18.958,10.208l-5.417,11.667l-26.042,13.125v1.75
            l-3.167,2c0,0-0.792-0.792-1.292-0.625s-0.458,1.542-0.458,1.542s-0.75-0.229-1.333,0.125c-0.583,0.354-0.458,0.917-0.458,0.917
            l-12.375,6.958l-0.875-0.331l-3.875,1.831l-1.542-7.458l-61.625-36.625L442.5,221.49l-70.292-40.417l-0.771,0.458l-0.188-69.208
            l-81.375,47.354v20.521l39.458,23.375l10.125,52.5l51.125,29.292c0,0,0.666-2,1.083-1.958s1.458,1.167,1.458,1.167
            s0.583-1.375,1.167-1.333s0.958,0.917,0.958,0.917s1.5-0.5,1.875,0.042s0.042,1.792,0.042,1.792s1.458-0.5,1.625,0.208
            s-0.167,1.5-0.167,1.5l-0.375,1.5l34.083,20.292L412.539,321.2l-6.75,14.208l-8.208,4.459l79.104,44.938v47.75l14.75-9.25v-9.688
            c0,0-0.063-1.5,0.25-1.938c0.313-0.438,1.063-1.25,1.063-1.25l17.063-9.375c0,0,1.563-0.438,2.313,0
            c0.75,0.438,1.938,2.25,1.938,2.25L514,409.865l13.125-7.688l4.334-8.714c-0.011,0.003-16.393,3.551-59.378-24.272
            c-43-27.833-30.667-37-24.5-42.5s7.167-5.584,7.167-5.584l-0.333-34.325l36.997-21.85l-19.83-11.408l0.417-0.25v-40.292
            l19.722,11.708l-0.221,40.189l38.416-22.688l24.334,14.167l0.166,45.333l22.334,13.167l2.5-5.833l0.604-0.043l10.25,5.813
            l0.25,0.75l-1.875,4.75l1.438,0.813l0.25,1.688l1.75,1.063c0,0,13.501,9.667,14.834,21.667s-2.501,30.999-13.334,39.666
            s-18,7.167-18,7.167l-23.976-12.619l61.375,35.063l32.313-30.563L668,397.803l-0.333,63.438l81-46.834v-15.75L675.083,356.574z
             M517.247,248.565l-16.042,9.292v-27.625l16.042,9.292V248.565z M651.081,357.607c0-0.25-20.5-12.084-20.5-12.084v-12.666
            l20.5-11.75C651.081,321.107,651.081,357.857,651.081,357.607z"
          />
          <polygon
            fill={FILL_COLOR}
            points="336.417,374.906 308.542,390.906 308.542,397.573 336.417,413.698"
          />
          <polygon
            fill={FILL_COLOR}
            points="641.664,405.232 662.039,417.607 662.164,458.294 640.727,445.544 641.477,444.794"
          />
          <polygon
            fill={FILL_COLOR}
            points="358.477,369.607 358.477,400.982 342.289,410.357 342.289,372.232 358.477,362.982"
          />
          <polygon
            fill={FILL_COLOR}
            points="314.102,216.732 314.102,257.044 294.477,245.669 294.977,245.419 294.852,205.544"
          />
        </g>
      </svg>
    </>
  );
};

export default Hovers;
