import React, { useEffect, useState } from 'react';
import { MultipleModalPage } from 'global';
import { Row, Col } from 'reactstrap';
import style from './styles/multiple-modal.module.scss';

interface MultipleModalProps {
  data: {index :number, pages :Array<MultipleModalPage>};
  // eslint-disable-next-line no-unused-vars
  onClose: (index :number) => void;
}
const MultipleModal: React.FC<MultipleModalProps> = ({ data, onClose }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => () => {
    setCurrentPageIndex(0);
    setCurrentImageIndex(0);
  }, []);
  return (
    <>
      <Row className={style.multipleModal}>
        { (data?.pages?.length > 1)
          && (
            <div className="page-slider">
              <span
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (currentPageIndex >= 1) {
                    setCurrentPageIndex(currentPageIndex - 1);
                  }
                }}
              />
              <span
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (currentPageIndex < (data.pages.length - 1)) {
                    setCurrentPageIndex(currentPageIndex + 1);
                  }
                }}
              />
            </div>
          )}
        <Col className="close">
          <button
            type="button"
            onClick={() => {
              onClose(data.index);
            }}
          >
            <span>+</span>
          </button>
        </Col>
        <Col>
          {data.pages.map((page, index) => (
            index === currentPageIndex && (
              <Row key={`ma-${index}`} className="bodyParent">
                <Col>
                  <Row className="header">
                    <Col />
                    <Col><h1>{page.subTitle}</h1></Col>
                  </Row>
                  <Row className="body">
                    <Col>
                      <div style={{
                        backgroundImage: `url(./img/modal/${page?.images
                          ? page.images[currentImageIndex] : ''}`,
                      }}
                      />
                    </Col>
                    <Col>
                      <div>
                        {page.title
                        && (
                          <h2 style={{
                            paddingTop: '8px',
                          }}
                          >{page.title}
                          </h2>
                        )}
                        <p>{page.text}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          ))}
          <Row className="footer">
            <Col className="slider">
              <Row>
                { data.pages?.length
                  && data.pages[currentPageIndex].images.map((_, index) => (
                    <Col
                      key={`mb-${index}`}
                      className="slide"
                    >
                      <span
                        className={`${index === currentImageIndex
                          ? 'active' : ''}`}
                        tabIndex={0}
                        role="button"
                        onClick={() => {
                          setCurrentImageIndex(index);
                        }}
                      />
                    </Col>
                  )) }
              </Row>
            </Col>
            <Col className="link">
              <i style={{
                visibility: `${data.pages[
                  currentPageIndex].link !== '' ? 'visible' : 'hidden'}`,
              }}
              >
                click
                <a
                  rel="noreferrer"
                  href={data.pages[currentPageIndex].link}
                  target="_blank"
                > here
                </a> to explore more
              </i>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MultipleModal;
